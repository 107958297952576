<template>
  <div id="documentation">
    <Navigation/>
    <div class="container-fixed">
      <div class="doc-services-box">
        <div class="doc-services">
          <div>
            <div class="doc-services-title animate__animated animate__fadeInLeft">We provide</div>
            <ul class="service-links animate__animated animate__fadeInLeft animate__delay-1s">
              <li v-for="service in services" v-bind:key="service.id" :class="service.title == selected_service.title ? ' selected':''">
                <a href="javascript:void(0)" @click="previewService(service.id)">{{service.title}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="service-preview animate__animated animate__fadeInRight" :style="'background-image: url(' + selected_service.cover + ');'">
        <div class="service-body animate__animated animate__fadeInLeft">
          <div class="service-title animate__animated animate__fadeInDown animate__delay-1s">{{selected_service.title}}</div>
          <div class="service-desc animate__animated animate__fadeInDown animate__delay-1s">{{selected_service.short_desc}}</div>
          <div class="service-link animate__animated animate__fadeInDown animate__delay-1s">
            <a :href="'/documentation/' + selected_service.url">자세히 보기</a>
          </div>
        </div>
      </div>
    </div>
    <BgTextree/>
  </div>
</template>

<script>
import store from '@/store'

export default {
  components: {
    Navigation: () => import('@/components/nav.vue'),
    BgTextree: () => import('@/components/bg-textree.vue')
  },
  computed: {
    services: () => store.state.documentation.services
  },
  data: () => ({
    selected_service: {}
  }),
  mounted() {
    store.commit('SET_NAV_PAGE', 'documentation')
    store.commit('SET_NAV_THEME', 'dark')
    this.previewService(1);
  },
  methods: {
    previewService: function (id) {
      for (let service of this.services) {
        if (service.id == id) {
          this.selected_service = service;
        }
      }
    }
  }
}
</script>
<style scoped>

.doc-services-box {
  max-width: 1600px;
  margin: 15% auto;
  height: 50%;
  z-index: 144;
  position: relative;
}
.doc-services {
  margin-top: -5em;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.doc-services > div {
  width: calc(100% - 100px - 48px);
  padding-left: 100px;
  padding-right: 48px;
}

.doc-services-title {
  color: #008ED6;
  padding: 0 12px;
  font-family: "Montserrat";
  font-size: 22px;
  line-height: 70px;
  font-weight: bold;
}

ul.service-links {
  list-style-type: none;
  padding: 0;
}
ul.service-links li a {
  color: #CFCFCF;
  padding: 6px 12px;
  font-family: "Roboto";
  font-size: 45px;
  line-height: 70px;
  text-decoration: none;
  position: relative;
}
ul.service-links li.selected a {
  color: #2E2E2E;
}
ul.service-links li.selected a::before {
  position: absolute;
  border-top: 1px solid #707070;
  content: " ";
  width: 75px;
  top: 50%;
  left: -100px;
}

.service-preview {
  position: absolute;
  right: 0;
  bottom: 0;
  width: calc(100% - 45%);
  height: calc(100% - 100px);
  background-size: cover;
  background-position: center;
  z-index: 1;
}
.service-body {
  position: absolute;
  left: 0;
  bottom: 0;
  width: calc(75% - 48px);
  background: #008ED6;
  color: white;
  padding: 24px;
}

.service-title {
  font-family: "Nanum Gothic";
  font-size: 40px;
  font-weight: bold;
  line-height: 30px;
  padding: 24px;
}
.service-desc {
  font-family: "Nanum Gothic";
  font-size: 24px;
  font-weight: normal;
  line-height: 45px;
  padding: 0 24px;
  width: calc(75% - 12px);
}

.service-link a {
  display: inline-block;
  color: white;
  background: #F1B500;
  padding: 12px 24px;
  border-radius: 31px;
  text-decoration: none;
  z-index: 1024;
  margin: 12px 0 0;
}

@media(max-width: 1440px) {
  .doc-services-box {
    margin-top: calc(15% + 66px);
  }
  ul.service-links li a {
    font-size: 36px;
  }
  .service-preview {
    width: calc(100% - 36%);
  }
  .service-body {
    width: calc(85% - 48px);
  }
  .service-title {
    font-size: 36px;
  }
  .service-desc {
    line-height: 36px;
  }
}

@media(max-width: 1024px) {
  .doc-services-box {
    margin-top: calc(25% + 66px);
  }
  .doc-services > div {
    padding-left: 64px;
  }
  ul.service-links li.selected a::before {
    left: -80px;
  }
  ul.service-links li a {
    font-size: 24px;
    line-height: 48px;
  }
  .service-preview {
    width: calc(100% - 36%);
    z-index: 199;
  }
  .service-body {
    width: calc(100% - 48px);
  }
  .service-title {
    padding: 12px 0;
    font-size: 24px;
  }
  .service-desc {
    font-size: 16px;
    padding: 0;
    line-height: 24px;
  }
  .service-link a {
    padding: 12px 24px;
  }
}

@media(max-width: 768px) {
  .doc-services-box {
    margin-top: calc(35% + 66px);
  }
  .doc-services > div {
    padding-left: 32px;
  }
}

@media(max-width: 576px) {
  .doc-services-box {
    margin-top: calc(45% + 66px);
  }
  .doc-services {
    margin-top: 25%;
  }
  .doc-services > div {
    padding-left: 24px;
    width: calc(100% - 48px);
  }
  .service-preview {
    width: calc(100% - 50%);
  }
  .doc-services-title {
    font-size: 18px;
    padding: 0;
    line-height: 36px;
  }
  ul.service-links li a {
    font-size: 16px;
    padding: 0;
    line-height: 36px;
  }
  .service-body {
    width: calc(100% - 24px);
    height: calc(100% - 24px);
    padding: 12px;
    background: rgba(0, 0, 0, .5);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .service-title {
    font-size: 18px;
    line-height: 24px;
  }
  .service-desc {
    font-size: 12px;
    width: 100%;
    line-height: 18px;
  }
  .service-link {
    padding: 12px 0;
  }
  .service-link a {
    background: transparent;
    border: 1px solid white;
    border-radius: 0;
    display: block;
    padding: 6px;
  }
}

@media(max-height: 680px) {
  .doc-services-box {
    margin-top: calc(25% + 66px);
  }
}
</style>

<style>
#plusfriend-chat-button {
  bottom: 76px;
}
#plusfriend-chat-button a {
  right: -128px;
}

@media(max-width: 1440px) {
  #plusfriend-chat-button a {
    right: 12px;
  }
}

@media(max-width: 1024px) {
  #plusfriend-chat-button {
    bottom: 200px;
  }
}

@media(max-width: 576px) {
  #plusfriend-chat-button {
    bottom: 24px;
  }
}
</style>
